// Here you can add other styles
body {
  font-family: 'PT Sans', sans-serif;
}

.btn {
  font-weight: 700;
}
.titleHeader{
  font-weight: bold !important;
  font-size: 18px !important;
}
.c-datatable-filter input{
  margin-left: 20px !important;
}

.iconSearchBar{
  position: absolute;
  margin-top: 10px;
}